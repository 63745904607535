<template>
  <b-card-code>
    <validation-observer ref="GroupAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="updateActivity">
        <!-- Project Name -->
        <b-form-group label="Activity Name" label-for="activity_name">
          <validation-provider
            #default="{ errors }"
            name="Activity Name"
            :rules="{ regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/ }"
          >
            <b-form-input
              id="activity_name"
              v-model="activity_name"
              :state="errors.length > 0 ? false : null"
              name="activity_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- cdr_id -->
        <b-form-group label="Project" label-for="cdr">
          <v-select
            v-model="project"
            label="text"
            placeholder="--Select Project--"
            :options="projects"
            autocomplete
            :reduce="(project) => project.value"
          />
        </b-form-group>
        <b-form-group label="Start Date" label-for="Start Date">
          <flat-pickr
            placeholder="Start Date"
            v-model="startDate"
            class="form-control"
          />
        </b-form-group>
        <b-form-group label="End Date" label-for="End Date">
          <flat-pickr
            placeholder="End Date"
            v-model="endDate"
            class="form-control"
          />
        </b-form-group>
        <b-button type="submit" variant="primary" :disabled="invalid" size="sm">
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      activity_name: "",
      startDate: "",
      endDate: "",
      organization: !this.$store.state.app.user.is_staff
        ? this.$store.state.app.user.profile.organization
        : this.$store.state.app.org_id,
      // organization: "",
      projects: [],
      project: "",
    };
  },
  created: function () {
    this.load();
    this.loadDetails();
  },
  methods: {
    load: function () {
      if (this.is_staff) {
        // Load oragnization drop down
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "project/project/?org_id=" +
            this.organization,
        };
        var self = this;
        this.$http(o_options).then((res) => {
          // console.log(res.data)
          res.data.map(function (value, key) {
            let pro = {
              value: res.data[key].project_id,
              text: res.data[key].project_name,
            };
            self.projects.push(pro);
          });
        });
      }
    },
     loadDetails: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "project/activity/" +
          this.$route.params.id,
      };
      this.$http(options).then((res) => {
        this.items = res.data;
        this.project = res.data.project_id;
        this.activity_name = res.data.activity_name;
        this.cdr_id = res.data.cdr_id;
        this.startDate = res.data.start_date;
        this.endDate = res.data.end_date;
      });
    },
    updateActivity: function () {
      this.$refs.GroupAddForm.validate().then((success) => {
        if (success) {
          //call to axios
          var self = this;
          let data = {
            activity_name: this.activity_name,
            project_id: this.project,
            start_date: this.startDate,
            end_date: this.endDate,
          };
          const options = {
            method: "PUT",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "project/activity/" + this.$route.params.id + "/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.activity_id) {
              this.$router.push({ name: "List Activity" });
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successfully added Activity!",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/libs/vue-select.scss';

</style>